var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "event-id",
                          name: "eventId",
                          label: _vm.$t("Id do evento"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.eventId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "eventId", $$v)
                          },
                          expression: "filters.eventId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "event-id",
                          name: "name",
                          label: _vm.$t("Nome"),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "event-store_id", required: false },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "eventTable",
            staticClass: "bordered",
            attrs: {
              id: "event-table",
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("EVENT.NAME"),
                "male"
              ),
              fields: _vm.fields,
              items: _vm.events,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(delivery)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.delivery, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(consigned)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.consigned, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-active": row.item.status === "Active",
                        "show-update":
                          row.item.status !== "Finished" &&
                          row.item.status !== "Canceled",
                        "show-delete":
                          row.item.status !== "Finished" &&
                          row.item.status !== "Canceled",
                        "show-activate":
                          row.item.status !== "Finished" &&
                          row.item.status !== "Canceled",
                        "show-deactivate":
                          row.item.status !== "Finished" &&
                          row.item.status !== "Canceled",
                        buttons: [
                          {
                            icon: "search",
                            variant: "primary",
                            title: _vm.$t("Visualizar evento"),
                            event: "event-read-only",
                          },
                          {
                            icon: "check",
                            variant: "primary",
                            title: _vm.$t("Finalizar evento"),
                            event: "finish",
                            hidden:
                              row.item.status === "Finished" ||
                              row.item.status === "Canceled",
                          },
                          {
                            icon: "x-circle",
                            variant: "danger",
                            title: _vm.$t("Cancelar evento"),
                            event: "cancel",
                            hidden:
                              row.item.status === "Finished" ||
                              row.item.status === "Canceled",
                          },
                        ].filter(function (button) {
                          return !button.hidden
                        }),
                      },
                      on: {
                        "event-read-only": function ($event) {
                          return _vm.showEventReadOnly(row)
                        },
                        update: function ($event) {
                          return _vm.editEvent(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteEventLocal(row)
                        },
                        activate: function ($event) {
                          return _vm.activateEvent(row)
                        },
                        deactivate: function ($event) {
                          return _vm.deactivateEvent(row)
                        },
                        finish: function ($event) {
                          return _vm.finishEvent(row)
                        },
                        cancel: function ($event) {
                          return _vm.cancelEvent(row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "event-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Event")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Evento") },
            on: { click: _vm.onCreateEvent },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }